import React from 'react';

export const FORM_UPGRADE_BETA_TESTERS = [
  'act_ba7d12ab27bb', // Donately Demo account
  'act_2895f06d28ce', // Future now production account
  'act_2bc3d7af76a5', // spencersgiveback
  'act_1ca773eaa14c', // Bryan Test EUR
  'act_8740c65dd898', // Sebastian test staging
  // 'act_04decc695fb7', // resourcecenter-61f5
  'act_38777bf77861', // sarahsdonately
  'act_838f678d7924', // City-thought
  // 'act_4da7535bab21', // delightministries
  // 'act_7a8f7f9feb75', // hopeandhome
  // 'act_f16691dcba65',  // patternsofevidencefoundation
  // 'act_e3cf5c022e3e',  // scripturememory
  // 'act_c7968293a9e8', // plainfieldredpridebandboosters
  // 'act_8e9746a6d32f', // don-efficace
  // 'act_534e0cf6bff6', //fllvancouver
  // 'act_e34714abb68b', // ukraniansindachau
  // 'act_12c09ec70ddb', // newporttreeconservancy
  // 'act_2e3465764e9f', // watersideworkshops
  // 'act_d0d3bbd323a9', // allnationschurch
  // 'act_8492b4bcfabf', // unitedwayofmetrochicago
  // 'act_45e33a892b6c', // ifternship
  // 'act_61acad9f335e', // ctahorse
  // 'act_5934eba2fe0b', // rwandachildren
  // 'act_e4f930bd140a', // cic
  // 'act_aadacde7a543', // nominetwork
  // 'act_2664bb1293bd', // thejewishfilmproject
  // 'act_1e9e5132651d', // 1517legacy
  // 'act_8edd2ded2635', // hbcucampaignfund
  // 'act_b98df5bed0c5', // moonshotev
  // 'act_cfeea05ee38c', // nchillel
  // 'act_2e735289e052', // swellcreativegroup
  // 'act_aa21b4d3e920', // waterkeeperalliance
  // 'act_6203944f766c', // bayan
  // 'act_510a1c26ac8e', // brownbagladyinc
  // 'act_5fced8744d83', // thesararafoundation
  // 'act_2ff6fdc51f49', // thegiftedgown
  // 'act_00b16928c1b0', // coleridgeclosetinc
  // 'act_f01f05607de8', // themeehubinc
  // 'act_7406f6898322', // totalrestorationoutreachcenter
  // 'act_a970857da28e', // telljapan
  // 'act_84ce67ccdba4', // fundraiserorg
  // 'act_44b907df0e86', // fortressforher
  // 'act_9b8b84377c50', // newcreationcounselingcenter
];

export const FILESTACK_API_KEY =
  process.env.FILESTACK_API_KEY || 'AjcMrbLzRaWtwOLp5pMogz';
// https://www.filestack.com/docs/uploads/pickers/web/#picker-options
export const FILESTACK_OPTIONS = {
  accept: 'image/*',
  maxFiles: 1,
  minFiles: 1,
  maxSize: 10 * 1024 * 1024,
  storeTo: {
    location: 's3',
  },
  fromSources: ['local_file_system', 'url', 'imagesearch', 'webcam'],
};

export const FACEBOOK_APP_ID =
  process.env.NODE_ENV === 'production'
    ? process.env.PAGES_FACEBOOK_APP_ID
    : '1474770359261872';

export const HEADERS = {
  'cache-control': 'no-cache',
  'Donately-Version': process.env.CURRENT_API_VERSION,
  'Content-Type': 'application/json;charset=utf-8',
  Accept: 'application/json, text/plain, */*',
};

export const PRINT_LEGEND =
  'Where applicable, no goods or services were received in return for these gifts.';

const daysInMonth = function() {
  const days = [];
  for (let day = 1; day <= 31; day += 1) {
    days.push({ name: day, value: day });
  }
  return days;
};

export const DAYSINMONTH = daysInMonth();

const countriesList = [
  { name: ' ', value: '' },
  { name: 'United States', value: 'US' },
  { name: 'Afghanistan', value: 'AF' },
  { name: 'Åland Islands', value: 'AX' },
  { name: 'Albania', value: 'AL' },
  { name: 'Algeria', value: 'DZ' },
  { name: 'American Samoa', value: 'AS' },
  { name: 'AndorrA', value: 'AD' },
  { name: 'Angola', value: 'AO' },
  { name: 'Anguilla', value: 'AI' },
  { name: 'Antarctica', value: 'AQ' },
  { name: 'Antigua and Barbuda', value: 'AG' },
  { name: 'Argentina', value: 'AR' },
  { name: 'Armenia', value: 'AM' },
  { name: 'Aruba', value: 'AW' },
  { name: 'Australia', value: 'AU' },
  { name: 'Austria', value: 'AT' },
  { name: 'Azerbaijan', value: 'AZ' },
  { name: 'Bahamas', value: 'BS' },
  { name: 'Bahrain', value: 'BH' },
  { name: 'Bangladesh', value: 'BD' },
  { name: 'Barbados', value: 'BB' },
  { name: 'Belarus', value: 'BY' },
  { name: 'Belgium', value: 'BE' },
  { name: 'Belize', value: 'BZ' },
  { name: 'Benin', value: 'BJ' },
  { name: 'Bermuda', value: 'BM' },
  { name: 'Bhutan', value: 'BT' },
  { name: 'Bolivia', value: 'BO' },
  { name: 'Bosnia and Herzegovina', value: 'BA' },
  { name: 'Botswana', value: 'BW' },
  { name: 'Bouvet Island', value: 'BV' },
  { name: 'Brazil', value: 'BR' },
  { name: 'British Indian Ocean Territory', value: 'IO' },
  { name: 'Brunei Darussalam', value: 'BN' },
  { name: 'Bulgaria', value: 'BG' },
  { name: 'Burkina Faso', value: 'BF' },
  { name: 'Burundi', value: 'BI' },
  { name: 'Cambodia', value: 'KH' },
  { name: 'Cameroon', value: 'CM' },
  { name: 'Canada', value: 'CA' },
  { name: 'Cape Verde', value: 'CV' },
  { name: 'Cayman Islands', value: 'KY' },
  { name: 'Central African Republic', value: 'CF' },
  { name: 'Chad', value: 'TD' },
  { name: 'Chile', value: 'CL' },
  { name: 'China', value: 'CN' },
  { name: 'Christmas Island', value: 'CX' },
  { name: 'Cocos (Keeling) Islands', value: 'CC' },
  { name: 'Colombia', value: 'CO' },
  { name: 'Comoros', value: 'KM' },
  { name: 'Congo', value: 'CG' },
  { name: 'Congo, The Democratic Republic of the', value: 'CD' },
  { name: 'Cook Islands', value: 'CK' },
  { name: 'Costa Rica', value: 'CR' },
  { name: "Cote D'Ivoire", value: 'CI' },
  { name: 'Croatia', value: 'HR' },
  { name: 'Cuba', value: 'CU' },
  { name: 'Cyprus', value: 'CY' },
  { name: 'Czech Republic', value: 'CZ' },
  { name: 'Denmark', value: 'DK' },
  { name: 'Djibouti', value: 'DJ' },
  { name: 'Dominica', value: 'DM' },
  { name: 'Dominican Republic', value: 'DO' },
  { name: 'Ecuador', value: 'EC' },
  { name: 'Egypt', value: 'EG' },
  { name: 'El Salvador', value: 'SV' },
  { name: 'Equatorial Guinea', value: 'GQ' },
  { name: 'Eritrea', value: 'ER' },
  { name: 'Estonia', value: 'EE' },
  { name: 'Ethiopia', value: 'ET' },
  { name: 'Falkland Islands (Malvinas)', value: 'FK' },
  { name: 'Faroe Islands', value: 'FO' },
  { name: 'Fiji', value: 'FJ' },
  { name: 'Finland', value: 'FI' },
  { name: 'France', value: 'FR' },
  { name: 'French Guiana', value: 'GF' },
  { name: 'French Polynesia', value: 'PF' },
  { name: 'French Southern Territories', value: 'TF' },
  { name: 'Gabon', value: 'GA' },
  { name: 'Gambia', value: 'GM' },
  { name: 'Georgia', value: 'GE' },
  { name: 'Germany', value: 'DE' },
  { name: 'Ghana', value: 'GH' },
  { name: 'Gibraltar', value: 'GI' },
  { name: 'Greece', value: 'GR' },
  { name: 'Greenland', value: 'GL' },
  { name: 'Grenada', value: 'GD' },
  { name: 'Guadeloupe', value: 'GP' },
  { name: 'Guam', value: 'GU' },
  { name: 'Guatemala', value: 'GT' },
  { name: 'Guernsey', value: 'GG' },
  { name: 'Guinea', value: 'GN' },
  { name: 'Guinea-Bissau', value: 'GW' },
  { name: 'Guyana', value: 'GY' },
  { name: 'Haiti', value: 'HT' },
  { name: 'Heard Island and Mcdonald Islands', value: 'HM' },
  { name: 'Holy See (Vatican City State)', value: 'VA' },
  { name: 'Honduras', value: 'HN' },
  { name: 'Hong Kong', value: 'HK' },
  { name: 'Hungary', value: 'HU' },
  { name: 'Iceland', value: 'IS' },
  { name: 'India', value: 'IN' },
  { name: 'Indonesia', value: 'ID' },
  { name: 'Iran, Islamic Republic Of', value: 'IR' },
  { name: 'Iraq', value: 'IQ' },
  { name: 'Ireland', value: 'IE' },
  { name: 'Isle of Man', value: 'IM' },
  { name: 'Israel', value: 'IL' },
  { name: 'Italy', value: 'IT' },
  { name: 'Jamaica', value: 'JM' },
  { name: 'Japan', value: 'JP' },
  { name: 'Jersey', value: 'JE' },
  { name: 'Jordan', value: 'JO' },
  { name: 'Kazakhstan', value: 'KZ' },
  { name: 'Kenya', value: 'KE' },
  { name: 'Kiribati', value: 'KI' },
  { name: "Korea, Democratic People'S Republic of", value: 'KP' },
  { name: 'Korea, Republic of', value: 'KR' },
  { name: 'Kuwait', value: 'KW' },
  { name: 'Kyrgyzstan', value: 'KG' },
  { name: "Lao People'S Democratic Republic", value: 'LA' },
  { name: 'Latvia', value: 'LV' },
  { name: 'Lebanon', value: 'LB' },
  { name: 'Lesotho', value: 'LS' },
  { name: 'Liberia', value: 'LR' },
  { name: 'Libyan Arab Jamahiriya', value: 'LY' },
  { name: 'Liechtenstein', value: 'LI' },
  { name: 'Lithuania', value: 'LT' },
  { name: 'Luxembourg', value: 'LU' },
  { name: 'Macao', value: 'MO' },
  { name: 'Macedonia, The Former Yugoslav Republic of', value: 'MK' },
  { name: 'Madagascar', value: 'MG' },
  { name: 'Malawi', value: 'MW' },
  { name: 'Malaysia', value: 'MY' },
  { name: 'Maldives', value: 'MV' },
  { name: 'Mali', value: 'ML' },
  { name: 'Malta', value: 'MT' },
  { name: 'Marshall Islands', value: 'MH' },
  { name: 'Martinique', value: 'MQ' },
  { name: 'Mauritania', value: 'MR' },
  { name: 'Mauritius', value: 'MU' },
  { name: 'Mayotte', value: 'YT' },
  { name: 'Mexico', value: 'MX' },
  { name: 'Micronesia, Federated States of', value: 'FM' },
  { name: 'Moldova, Republic of', value: 'MD' },
  { name: 'Monaco', value: 'MC' },
  { name: 'Mongolia', value: 'MN' },
  { name: 'Montserrat', value: 'MS' },
  { name: 'Morocco', value: 'MA' },
  { name: 'Mozambique', value: 'MZ' },
  { name: 'Myanmar', value: 'MM' },
  { name: 'Namibia', value: 'NA' },
  { name: 'Nauru', value: 'NR' },
  { name: 'Nepal', value: 'NP' },
  { name: 'Netherlands', value: 'NL' },
  { name: 'Netherlands Antilles', value: 'AN' },
  { name: 'New Caledonia', value: 'NC' },
  { name: 'New Zealand', value: 'NZ' },
  { name: 'Nicaragua', value: 'NI' },
  { name: 'Niger', value: 'NE' },
  { name: 'Nigeria', value: 'NG' },
  { name: 'Niue', value: 'NU' },
  { name: 'Norfolk Island', value: 'NF' },
  { name: 'Northern Mariana Islands', value: 'MP' },
  { name: 'Norway', value: 'NO' },
  { name: 'Oman', value: 'OM' },
  { name: 'Pakistan', value: 'PK' },
  { name: 'Palau', value: 'PW' },
  { name: 'Palestinian Territory, Occupied', value: 'PS' },
  { name: 'Panama', value: 'PA' },
  { name: 'Papua New Guinea', value: 'PG' },
  { name: 'Paraguay', value: 'PY' },
  { name: 'Peru', value: 'PE' },
  { name: 'Philippines', value: 'PH' },
  { name: 'Pitcairn', value: 'PN' },
  { name: 'Poland', value: 'PL' },
  { name: 'Portugal', value: 'PT' },
  { name: 'Puerto Rico', value: 'PR' },
  { name: 'Qatar', value: 'QA' },
  { name: 'Reunion', value: 'RE' },
  { name: 'Romania', value: 'RO' },
  { name: 'Russian Federation', value: 'RU' },
  { name: 'RWANDA', value: 'RW' },
  { name: 'Saint Helena', value: 'SH' },
  { name: 'Saint Kitts and Nevis', value: 'KN' },
  { name: 'Saint Lucia', value: 'LC' },
  { name: 'Saint Pierre and Miquelon', value: 'PM' },
  { name: 'Saint Vincent and the Grenadines', value: 'VC' },
  { name: 'Samoa', value: 'WS' },
  { name: 'San Marino', value: 'SM' },
  { name: 'Sao Tome and Principe', value: 'ST' },
  { name: 'Saudi Arabia', value: 'SA' },
  { name: 'Senegal', value: 'SN' },
  { name: 'Serbia and Montenegro', value: 'CS' },
  { name: 'Seychelles', value: 'SC' },
  { name: 'Sierra Leone', value: 'SL' },
  { name: 'Singapore', value: 'SG' },
  { name: 'Slovakia', value: 'SK' },
  { name: 'Slovenia', value: 'SI' },
  { name: 'Solomon Islands', value: 'SB' },
  { name: 'Somalia', value: 'SO' },
  { name: 'South Africa', value: 'ZA' },
  { name: 'South Georgia and the South Sandwich Islands', value: 'GS' },
  { name: 'Spain', value: 'ES' },
  { name: 'Sri Lanka', value: 'LK' },
  { name: 'Sudan', value: 'SD' },
  { name: 'Suridisplay', value: 'SR' },
  { name: 'Svalbard and Jan Mayen', value: 'SJ' },
  { name: 'Swaziland', value: 'SZ' },
  { name: 'Sweden', value: 'SE' },
  { name: 'Switzerland', value: 'CH' },
  { name: 'Syrian Arab Republic', value: 'SY' },
  { name: 'Taiwan, Province of China', value: 'TW' },
  { name: 'Tajikistan', value: 'TJ' },
  { name: 'Tanzania, United Republic of', value: 'TZ' },
  { name: 'Thailand', value: 'TH' },
  { name: 'Timor-Leste', value: 'TL' },
  { name: 'Togo', value: 'TG' },
  { name: 'Tokelau', value: 'TK' },
  { name: 'Tonga', value: 'TO' },
  { name: 'Trinidad and Tobago', value: 'TT' },
  { name: 'Tunisia', value: 'TN' },
  { name: 'Turkey', value: 'TR' },
  { name: 'Turkmenistan', value: 'TM' },
  { name: 'Turks and Caicos Islands', value: 'TC' },
  { name: 'Tuvalu', value: 'TV' },
  { name: 'Uganda', value: 'UG' },
  { name: 'Ukraine', value: 'UA' },
  { name: 'United Arab Emirates', value: 'AE' },
  { name: 'United Kingdom', value: 'GB' },
  { name: 'United States Minor Outlying Islands', value: 'UM' },
  { name: 'Uruguay', value: 'UY' },
  { name: 'Uzbekistan', value: 'UZ' },
  { name: 'Vanuatu', value: 'VU' },
  { name: 'Venezuela', value: 'VE' },
  { name: 'Viet Nam', value: 'VN' },
  { name: 'Virgin Islands, British', value: 'VG' },
  { name: 'Virgin Islands, U.S.', value: 'VI' },
  { name: 'Wallis and Futuna', value: 'WF' },
  { name: 'Western Sahara', value: 'EH' },
  { name: 'Yemen', value: 'YE' },
  { name: 'Zambia', value: 'ZM' },
  { name: 'Zimbabwe', value: 'ZW' },
];
export const COUNTRIES_LIST = countriesList;

export const COUNTRIES = Object.entries(countriesList).flatMap(
  ([key, country]) => {
    if (country.value) {
      return country.value;
    }
    return [];
  }
);

export const STATES_LIST = [
  { name: '', value: '' },
  { name: 'Alabama', value: 'AL' },
  { name: 'Alaska', value: 'AK' },
  { name: 'Arizona', value: 'AZ' },
  { name: 'Arkansas', value: 'AR' },
  { name: 'California', value: 'CA' },
  { name: 'Colorado', value: 'CO' },
  { name: 'Connecticut', value: 'CT' },
  { name: 'Delaware', value: 'DE' },
  { name: 'District of Columbia', value: 'DC' },
  { name: 'Florida', value: 'FL' },
  { name: 'Georgia', value: 'GA' },
  { name: 'Hawaii', value: 'HI' },
  { name: 'Idaho', value: 'ID' },
  { name: 'Illinois', value: 'IL' },
  { name: 'Indiana', value: 'IN' },
  { name: 'Iowa', value: 'IA' },
  { name: 'Kansas', value: 'KS' },
  { name: 'Kentucky', value: 'KY' },
  { name: 'Louisiana', value: 'LA' },
  { name: 'Maine', value: 'ME' },
  { name: 'Maryland', value: 'MD' },
  { name: 'Massachusetts', value: 'MA' },
  { name: 'Michigan', value: 'MI' },
  { name: 'Minnesota', value: 'MN' },
  { name: 'Mississippi', value: 'MS' },
  { name: 'Missouri', value: 'MO' },
  { name: 'Montana', value: 'MT' },
  { name: 'Nebraska', value: 'NE' },
  { name: 'Nevada', value: 'NV' },
  { name: 'New Hampshire', value: 'NH' },
  { name: 'New Jersey', value: 'NJ' },
  { name: 'New Mexico', value: 'NM' },
  { name: 'New York', value: 'NY' },
  { name: 'North Carolina', value: 'NC' },
  { name: 'North Dakota', value: 'ND' },
  { name: 'Ohio', value: 'OH' },
  { name: 'Oklahoma', value: 'OK' },
  { name: 'Oregon', value: 'OR' },
  { name: 'Pennsylvania', value: 'PA' },
  { name: 'Rhode Island', value: 'RI' },
  { name: 'South Carolina', value: 'SC' },
  { name: 'South Dakota', value: 'SD' },
  { name: 'Tennessee', value: 'TN' },
  { name: 'Texas', value: 'TX' },
  { name: 'Utah', value: 'UT' },
  { name: 'Vermont', value: 'VT' },
  { name: 'Virginia', value: 'VA' },
  { name: 'Washington', value: 'WA' },
  { name: 'West Virginia', value: 'WV' },
  { name: 'Wisconsin', value: 'WI' },
  { name: 'Wyoming', value: 'WY' },
];

// Has the value to sum up to the current timestamp for setting the valid time of cache pages.
export const CACHE_VALID_TIME =
  parseInt(process.env.DATA_CACHE_VALID_MINUTES || 15) * 60000;

export const NON_PROFIT_BUSINESS_TYPES = [
  { name: 'Arts & culture', value: 'arts_culture' },
  { name: 'Education & Research', value: 'education_research' },
  { name: 'Environment & Animals', value: 'environment_animals' },
  { name: 'Government Entity', value: 'government_entity' },
  { name: 'Health Related', value: 'health' },
  { name: 'Human Services', value: 'human_services' },
  { name: 'International', value: 'international' },
  { name: 'Public Benefit', value: 'public_benefit' },
  { name: 'Religious', value: 'religious' },
  { name: 'Political', value: 'political' },
  { name: 'Other Non Profit', value: 'other_non_profit' },
];

export const FOR_PROFIT_BUSINESS_TYPES = [
  { name: 'Web Developer', value: 'web_developer' },
  { name: 'Marketing Agency', value: 'marketing_agency' },
  { name: 'Social Business', value: 'social_business' },
  { name: 'Individual', value: 'individual' },
  { name: 'Other For Profit', value: 'other_for_profit' },
];

export const ALL_BUSINESS_TYPES = [
  ...NON_PROFIT_BUSINESS_TYPES.map(el => ({ ...el, for_profit: false })),
  ...FOR_PROFIT_BUSINESS_TYPES.map(el => ({ ...el, for_profit: true })),
];

export const DEFAULT_FROALLA_CONFIG = {
  htmlRemoveTags: ['script', 'base'],
  height: 'auto',
  paragraphFormatSelection: true,
  imageUploadRemoteUrls: false,
  imageEditButtons: [
    'imageReplace',
    'imageCaption',
    'imageRemove',
    '|',
    'imageLink',
    'linkOpen',
    'linkEdit',
    'linkRemove',
    '-',
    'imageDisplay',
    'imageAlt',
    'imageSize',
  ],
  quickInsertEnabled: true,
  quickInsertButtons: ['ol', 'ul'],
  toolbarStickyOffset: 50,
  toolbarButtons: {
    moreText: {
      buttons: [
        'bold',
        'italic',
        'underline',
        'fontSize',
        'fontFamily',
        'textColor',
        'backgroundColor',
        'clearFormatting',
      ],
      buttonsVisible: 0,
    },
    moreParagraph: {
      buttons: [
        'paragraphFormat',
        'quote',
        'lineHeight',
        'alignLeft',
        'alignCenter',
        'alignRight',
        'indent',
        'outdent',
        'formatOL',
        'formatUL',
      ],
      buttonsVisible: 0,
    },
    moreRich: {
      buttons: ['insertLink', 'insertImage'],
      buttonsVisible: 0,
    },
    moreMisc: {
      buttons: [
        'insertTemplateButton',
        'undo',
        'redo',
        'fullscreen',
        'selectAll',
        'html',
        '|',
        'help',
      ],
      align: 'right',
      // buttonsVisible: 3,
    },
  },
  imageInsertButtons: ['imageByURL'],
  htmlAllowedTags: [
    'a',
    'b',
    'blockquote',
    'br',
    'center',
    'cite',
    'div',
    'em',
    'h1',
    'h2',
    'h3',
    'h4',
    'h5',
    'h6',
    'hr',
    'i',
    'iframe',
    'img',
    'li',
    'ol',
    'span',
    'strong',
    'sub',
    'sup',
    'p',
    'pre',
    'table',
    'td',
    'thead',
    'tr',
    'u',
    'ul',
  ],
  htmlAllowedAttrs: [
    'rel',
    'scrolling',
    'src',
    'style',
    'target',
    'title',
    'width',
    'height',
    'href',
  ],
};

export const PROMO_CODES = {
  HalfPrice: 'Half Price Promo',
};

export const PRICING_PLANS = {
  'Discontinued-Free': 'Discontinued-Free',
  Discontinued: 'Discontinued-Free',
  Free: 'Free',
  Starter: 'Starter',
  Team: 'Team',
  Enterprise: 'Enterprise',
};

export const PRICING_PLANS_INFO = {
  [PRICING_PLANS.Free]: {
    platform_fee: 4,
  },
  [PRICING_PLANS.Starter]: {
    platform_fee: 3.5,
    monthly_price: 19,
    yearly_price: 179,
  },
  [PRICING_PLANS.Team]: {
    platform_fee: 2,
    monthly_price: 69,
    yearly_price: 588,
  },
};

export const FEATURES_LIST = {
  // Free Features
  // SHARABLE_DONATION_FORM: 'Sharable Donation Form',
  // SHARABLE_DONATION_PAGES: 'Sharable Donation Pages',
  // PEER_TO_PEER_FUNDRAISING: 'Peer-to-Peer Fundraising',
  // RECURRING_DONATIONS: 'Recurring Donations',
  // CUSTOMIZABLE_DONATION_RECEIPTS: 'Customizable Donation Receipts',
  ZAPIER_INTEGRATIONS: 'Zapier Integrations',
  LIVE_CHAT_SUPPORT: 'Live Chat Support',

  // Starter ( & Discontinued-Free / Legacy Subscription) Features
  EMBEDDABLE_DONATION_FORMS: 'Embeddable Donation Forms',

  // Discontinued-Free (Legacy Subscription) Features
  EARMARK_CAMPAIGNS: 'EARMARK_CAMPAIGNS',
  UNLIMITED_ADMINS: 'UNLIMITED_ADMINS',
  ADMIN_NOTIFICATIONS: 'ADMIN_NOTIFICATIONS',

  // Starter  Features
  ADVANCED_FORM_CUSTOMIZATIONS: 'Advanced Form Customizations',
  PEER_TO_PEER_TEAMS_FUNDRAISING: 'Peer-to-Peer Fundraising with Teams',

  // Team & Enterprise Subscription Features
  NATIVE_INTEGRATIONS: 'NATIVE_INTEGRATIONS',
  CAMPAIGNS_DESIGN: 'CAMPAIGNS_DESIGN',
  CAMPAIGNS_SHARE: 'CAMPAIGNS_SHARE',
  EMBEDDABLE_PROGRESS_BAR: 'Embeddable Progress Bar',
  WEBHOOKS_MANAGEMENT: 'WEBHOOKS_MANAGEMENT',
  TEXT_ENGAGEMENT: 'TEXT_ENGAGEMENT',
};
